import { FuseNavigationItem } from '@fuse/components/navigation';

export const chatNavigation: FuseNavigationItem = {
    id      : 'chat',
    title   : 'Chat',
    type    : 'collapsable',
    icon    : 'forum',
    meta : {
        permission: 'chat.access',
    },
    children: [
        {
            id         : 'groups',
            title      : 'Group',
            type       : 'basic',
            iconFontSet: 'material-symbols-outlined',
            icon       : 'groups_3',
            link       : '/chat/group',
            meta : {
                permission: 'chat.group.access',
            },
        },
        {
            id   : 'conversations',
            title: 'Conversation',
            type : 'basic',
            icon : 'mat_outline:smb_share',
            link : '/chat/conversation',
            meta : {
                permission: 'chat.conversation.access',
            },
        },
        {
            id   : 'messages',
            title: 'Message',
            type : 'basic',
            icon : 'mat_outline:smb_share',
            link : '/chat/message',
            meta : {
                permission: 'chat.message.access',
            },
        },
    ],
};