import { FuseNavigationItem } from '@fuse/components/navigation';

export const digitaiNavigation: FuseNavigationItem = {
    id         : 'digitai',
    title      : 'Digitai',
    type       : 'collapsable',
    iconFontSet: 'material-symbols-outlined',
    icon       : 'psychology',
    children   : [
        {
            id   : 'digitaiDashboard',
            title: 'DigitaiDashboard',
            type : 'basic',
            iconFontSet: 'material-symbols-outlined',
            icon : 'dashboard',
            link : '/digitai/dashboard',
            meta : {
                permission: 'digitai.dashboard.access',
            },
        },
        {
            id   : 'fleetFile',
            title: 'FileManager',
            type : 'basic',
            icon : 'mat_outline:folder_open',
            link : '/digitai/fleet-file-operator',
            meta : {
                permission: 'digitai.fleetFile.access',
            },
        },
        {
            id   : 'alertLogs',
            title: 'AlertLog',
            type : 'basic',
            icon : 'mat_outline:airplane_ticket',
            link : '/digitai/alert-log-operator',
            meta : {
                permission: 'digitai.alertLogOperator.access',
            },
        },
        {
            id   : 'alertLogs',
            title: 'AlertLog',
            type : 'basic',
            icon : 'mat_outline:airplane_ticket',
            link : '/digitai/alert-log',
            meta : {
                permission: 'digitai.alertLog.access',
            },
        },
        {
            id   : 'issues',
            title: 'Issue',
            type : 'basic',
            icon : 'mat_outline:report_problem',
            link : '/digitai/issue',
            meta : {
                permission: 'digitai.issue.access',
            },
        },
        {
            id   : 'issueRequests',
            title: 'IssueRequest',
            type : 'basic',
            icon : 'mat_outline:feedback',
            link : '/digitai/issue-request',
            meta : {
                permission: 'digitai.issueRequest.access',
            },
        },
        {
            id   : 'fleetAircraftEngines',
            title: 'FleetAircraftEngine',
            type : 'basic',
            iconFontSet: 'material-symbols-outlined',
            icon : 'mode_fan',
            link : '/digitai/fleet-aircraft-engine',
            meta : {
                permission: 'digitai.fleetAircraftEngine.access',
            },
        },
        {
            id   : 'fleets',
            title: 'Fleet',
            type : 'basic',
            icon : 'mat_outline:flight',
            link : '/digitai/fleet',
            meta : {
                permission: 'digitai.fleet.access',
            },
        },
        {
            id      : 'masters',
            title   : 'Masters',
            type    : 'collapsable',
            icon    : 'settings',
            meta : {
                permission: 'digitai.master.access',
            },
            children: [
                {
                    id   : 'companies',
                    title: 'Company',
                    type : 'basic',
                    icon : 'mat_outline:apartment',
                    link : '/digitai/company',
                    meta : {
                        permission: 'digitai.company.access',
                    },
                },
                {
                    id   : 'tabs',
                    title: 'Tab',
                    type : 'basic',
                    icon : 'mat_outline:tab',
                    link : '/digitai/tab',
                    meta : {
                        permission: 'digitai.tab.access',
                    },
                },
                {
                    id   : 'engineModels',
                    title: 'EngineModel',
                    type : 'basic',
                    icon : 'mat_outline:apps',
                    link : '/digitai/engine-model',
                    meta : {
                        permission: 'digitai.engineModel.access',
                    },
                },
                {
                    id   : 'potentialIssues',
                    title: 'PotentialIssue',
                    type : 'basic',
                    icon : 'mat_outline:sync_problem',
                    link : '/digitai/potential-issue',
                    meta : {
                        permission: 'digitai.potentialIssue.access',
                    },
                },
                {
                    id   : 'aircraftModels',
                    title: 'AircraftModel',
                    type : 'basic',
                    icon : 'mat_outline:flight',
                    link : '/digitai/aircraft-model',
                    meta : {
                        permission: 'digitai.aircraftModel.access',
                    },
                },
                {
                    id   : 'aircraftStatuses',
                    title: 'AircraftStatus',
                    type : 'basic',
                    icon : 'mat_outline:sync_problem',
                    link : '/digitai/aircraft-status',
                    meta : {
                        permission: 'digitai.aircraftStatus.access',
                    },
                },
                {
                    id   : 'issueEngineStatuses',
                    title: 'IssueEngineStatus',
                    type : 'basic',
                    icon : 'mat_outline:build_circle',
                    link : '/digitai/issue-engine-status',
                    meta : {
                        permission: 'digitai.issueEngineStatus.access',
                    },
                },
            ],
        },
    ],
};