import { FuseNavigationItem } from '@fuse/components/navigation';
import { iamNavigation } from './apps/iam/iam.navigation';
import { oAuthNavigation } from './apps/o-auth/o-auth.navigation';
import { digitaiNavigation } from './apps/digitai/digitai.navigation';
import { chatNavigation } from './apps/chat/chat.navigation';

export const adminNavigation: FuseNavigationItem[] = [
    digitaiNavigation,
    iamNavigation,
    oAuthNavigation,
    chatNavigation
];
